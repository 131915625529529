import { Locales } from '@booking/types';
import { GetStaticProps } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// pages/404.js
export default function FourOhFourPage() {
  const { t } = useTranslation('404');
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center gap-8 rounded-lg bg-black/30 p-5 text-white sm:p-12">
        <div className="flex items-center text-[100px] leading-none">
          <span>4</span>
          <div className="h-18 w-18 relative ml-3 mr-3 mt-2 rounded-full border-4 border-solid border-white bg-white">
            <Image
              src="/illustrations/lab.png"
              alt="Illustration of a doctor"
              fill
              sizes="100vw"
            />
          </div>
          <span>4</span>
        </div>
        <div className="prose flex flex-col items-center text-center">
          <h1 className="text-white" role="heading">
            {t('heading')}
          </h1>
          <p className="mt-1 max-w-[400px]">{t('description')}</p>
        </div>
        <Link
          href="/"
          className="mt-2 rounded-full bg-white px-10 py-3 text-black no-underline"
        >
          {t('cta')}
        </Link>
      </div>
    </div>
  );
}

type StaticProps = {
  locale: Locales;
} & GetStaticProps;

export async function getStaticProps({ locale }: StaticProps) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['404'])),
    },
  };
}
